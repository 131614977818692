const left = 98526
const middle = 634526
const right = 172904

const text = "iasbgf90w74gt923ubtf-93fh49=-34bf-923b7f78932b4f9123brf9-h348901fb39bf9-123"

const milis = Date.now()

var CryptoJS = require("crypto-js")
var hash = CryptoJS.HmacSHA512(text+milis, "Index");

const myHeaders = new Headers()
myHeaders.append("XHash", hash.toString(CryptoJS.enc.Hex))
myHeaders.append("XTimestamp", (left+milis-(middle+right)).toString() )
myHeaders.append("Accept", "application/json")
myHeaders.append("Content-Type", "application/json;charset=utf-8" )

export default myHeaders