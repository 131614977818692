<script>
export default {
  name: "UserList",
  data() {
    return {
      users: [],
      prizes: [],
      currentUser: {
        id: "",
        username: "",
        points: ""
      },
      selectedPrize: ""
    }
  },
  computed: {
    sortedUsersByName() {
      let sortedUsers = this.users

      sortedUsers = sortedUsers.sort((a, b) => {
        let Aname = a.username
        let Bname = b.username

        if( Aname < Bname ) { return -1 }
        else if( Aname > Bname ) { return 1 }

        return 0
      })

      return sortedUsers
    },
    sortedPrizesByName() {
      let sortedPrizes = this.prizes

      sortedPrizes = sortedPrizes.sort((a, b) => {
        let Aname = a.name
        let Bname = b.name

        if( Aname < Bname ) { return -1 }
        else if( Aname > Bname ) { return 1 }

        return 0
      })

      return sortedPrizes
    }
  },
  methods: {
    async fetchUsers() {
      const res = await fetch(this.$apiRoute + "/users", {
        headers: this.$headers
      })
      this.users = await res.json()
    },
    async fetchPrizes() {
      const res = await fetch(this.$apiRoute + "/prize", {
        headers: this.$headers
      })
      this.prizes = await res.json()
    },
    async editUser(username) {
      const res = await fetch(this.$apiRoute + "/users/"+username+"/removePoints/"+this.selectedPrize, {
        method: "POST",
        headers: this.$headers,
      })

      if( !res.ok ) {
        switch ( res.status ) {
          case 404:
            alert("Nevybrali jste žádnou cenu. Pro odečtení bodů, jí prosím vyberte.");
            break;
          case 406:
            alert("Uživatel nemá dostatek bodů k získání této odměny.");
            break;
        }
      } else {
        alert("Uživateli jsme úspěšně odebrali Áčka za získání odměny.")
      }

      await this.fetchUsers()

      this.clearCurrentUser()
    },
    setCurrentUser(id, username, points) {
      this.currentUser = {
        id: id, username: username, points: points
      }
    },
    clearCurrentUser() {
      this.currentUser = {
        id: "", name: "", points: ""
      }
      this.selectedPrize = ""
    }
  },
  mounted() {
    this.fetchUsers()
  }
}
</script>

<template>
  <div class="d-flex align-items-center justify-content-between" style="padding: 10px 20px 10px 20px;">
    <h1>Seznam všech uživatelů</h1>
  </div>

  <table class="table table-responsive table-striped-columns align-middle table-hover">
    <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Uživatelské jméno</th>
      <th scope="col">Áčka</th>
    </tr>
    </thead>
    <tbody class="table-group-divider">
    <tr v-for="user in sortedUsersByName" :key="user.id">
      <td>{{user.id}}</td>
      <td>{{user.username}}</td>
      <td>{{user.points}}</td>
      <td style="width: 20%;">
        <div class="d-grid gap-3 mx-auto d-md-flex justify-content-center">
          <a role="button" class="btn btn-warning" @click="clearCurrentUser(); setCurrentUser(user.id, user.username, user.points); fetchPrizes()" data-bs-toggle="modal" data-bs-target="#editUserModal">Získat cenu</a>
        </div>
      </td>
    </tr>
    </tbody>
  </table>


  <div class="modal fade" id="editUserModal" tabindex="-1" aria-labelledby="editUserModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="editUserModalLabel">Upravit vybraného uživatele</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <form>
          <div class="modal-body">
            <div class="mb-3">
              <label for="user-name" class="col-form-label">Uživatelské jméno</label>
              <input type="text" class="form-control" id="user-name" v-model="currentUser.username" disabled>
            </div>
            <div class="mb-3">
              <label for="user-text" class="col-form-label">Počet Áček</label>
              <input type="text" class="form-control" id="user-text" v-model="currentUser.points" disabled>
            </div>
            <div class="mb-3">
              <div>Získaná odměna:</div>
              <select class="form-select" v-model="selectedPrize">
                <option selected disabled value="">Vyberte cenu, kterou si uživatel vyžádal</option>
                <option v-for="prize in sortedPrizesByName" :key="prize.id" :value="prize.id"> {{prize.name}} - {{prize.points}} </option>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="submit" class="btn btn-primary" data-bs-dismiss="modal" @click="editUser(this.currentUser.username); clearCurrentUser()">Edit user</button>
          </div>
        </form>
      </div>
    </div>
  </div>

</template>

<style scoped>

</style>