<script>
export default {
  name: "PrizeList",
  data() {
    return {
      prizes: [],
      currentPrize: {
        id: "",
        name: "",
        points: ""
      },
    }
  },
  computed: {
    sortPrizesByCost() {
      let sortedPrizes = this.prizes

      sortedPrizes = sortedPrizes.sort((a, b) => {
        let pointA = a.points
        let pointB = b.points

        if( pointA < pointB ) { return -1 }
        else if( pointA > pointB ) { return 1 }

        return 0
      })

      return sortedPrizes
    }
  },
  methods: {
    async fetchPrizes() {
      const res = await fetch(this.$apiRoute + "/prize", {
        headers: this.$headers
      })
      this.prizes = await res.json()
    },
    async editPrize(prizeID) {
      await fetch(this.$apiRoute + "/prize/"+prizeID, {
        method: "PUT",
        headers: this.$headers,
        body: JSON.stringify(this.currentPrize.points)
      })
      await this.fetchPrizes()

      this.clearCurrentPrize()
    },
    async addPrize() {
      await fetch(this.$apiRoute + "/prize", {
        method: "POST",
        headers: this.$headers,
        body: JSON.stringify(this.currentPrize)
      })
      await this.fetchPrizes()

      this.clearCurrentPrize()
    },
    async removePrize(prizeID) {
      await fetch(this.$apiRoute + "/prize/"+prizeID, {
        headers: this.$headers,
        method: "DELETE",
        mode: "cors",
        credentials: "include"
      })

      await this.fetchPrizes()
    },
    setCurrentPrize(id, name, prize) {
      this.currentPrize = {
        id: id, name: name, points: prize
      }
    },
    clearCurrentPrize() {
      this.currentPrize = {
        id: "", name: "", points: ""
      }
    }
  },
  mounted() {
    this.fetchPrizes()
  }
}
</script>

<template>

  <div class="d-flex align-items-center justify-content-between" style="padding: 10px 20px 10px 20px;">
    <h1>Seznam aktuální cen</h1>
    <a role="button" class="btn btn-primary btn-lg" @click="clearCurrentPrize()" data-bs-toggle="modal" data-bs-target="#newPrizeModal">Přidat novou cenu</a>
  </div>


  <table class="table table-responsive table-striped-columns align-middle table-hover">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Název</th>
        <th scope="col">Body</th>
      </tr>
    </thead>
    <tbody class="table-group-divider">
      <tr v-for="prize in sortPrizesByCost" :key="prize.id">
        <td>{{prize.id}}</td>
        <td>{{prize.name}}</td>
        <td>{{prize.points}}</td>
        <td style="width: 20%;">
          <div class="d-grid gap-3 mx-auto d-md-flex justify-content-center">
            <a role="button" class="btn btn-warning" @click="clearCurrentPrize(); setCurrentPrize(prize.id, prize.name, prize.points)" data-bs-toggle="modal" data-bs-target="#editPrizeModal">Změnit cenu</a>
            <a role="button" class="btn btn-danger" @click="removePrize(prize.id)">Odstranit</a>
          </div>
        </td>
      </tr>
    </tbody>
  </table>


  <div class="modal fade" id="editPrizeModal" tabindex="-1" aria-labelledby="editPrizeModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="editPrizeModalLabel">Upravit vybranou cenu</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <form>
          <div class="modal-body">
            <div class="mb-3">
              <label for="prize-name" class="col-form-label">Název</label>
              <input type="text" class="form-control" id="prize-name" v-model="currentPrize.name">
            </div>
            <div class="mb-3">
              <label for="prize-text" class="col-form-label">Body</label>
              <input type="text" class="form-control" id="prize-text" v-model="currentPrize.points">
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="submit" class="btn btn-primary" data-bs-dismiss="modal" @click="editPrize(this.currentPrize.id)">Edit prize</button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="modal fade" id="newPrizeModal" tabindex="-1" aria-labelledby="newPrizeModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="newPrizeModalLabel">Přidat novou cenu</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <form>
          <div class="modal-body">
            <div class="mb-3">
              <label for="prize-name" class="col-form-label">Název</label>
              <input type="text" class="form-control" id="prize-name" v-model="currentPrize.name">
            </div>
            <div class="mb-3">
              <label for="prize-text" class="col-form-label">Body</label>
              <input type="text" class="form-control" id="prize-text" v-model="currentPrize.points">
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="submit" class="btn btn-primary" @click="addPrize()" data-bs-dismiss="modal">Add prize</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>