import "bootstrap/dist/css/bootstrap.css"

import * as Vue from 'vue'
import App from './App.vue'
import {createRouter, createWebHashHistory} from "vue-router";
import UserList from "@/components/UserList.vue";
import PrizeList from "@/components/PrizeList.vue";
import Homepage from "@/components/Homepage.vue";
import myHeaders from "@/headers_setup";

const routes = [
    {path: "/users", name: "users", component: UserList},
    {path: "/prizes", name: "prizes", component: PrizeList},
    {path: "/", name: "home", component: Homepage}
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
    base: Homepage
})

const app = Vue.createApp(App)
app.use(router)
app.config.globalProperties.$headers = myHeaders
app.config.globalProperties.$apiRoute = "https://cjj-api.su.cvut.cz"
app.mount('#app')

import "bootstrap/dist/js/bootstrap.js"