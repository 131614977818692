<template>
  <div v-if="logged">
    <nav class="navbar navbar-expand-lg bg-light">
      <div class="container-fluid">
        <router-link class="navbar-brand" to="/">Administrace ČJJ</router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link class="nav-link active" to="/users">Uživatelé</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/prizes">Ceny</router-link>
            </li>
          </ul>
        </div>
        <a role="button" class="btn btn-primary" @click="clearCurrentAdmin()" data-bs-toggle="modal" data-bs-target="#newAdminModal">Přidat nového administrátora</a>
      </div>
    </nav>
    <router-view/>

    <div class="modal fade" id="newAdminModal" tabindex="-1" aria-labelledby="newAdminModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="newAdminModalLabel">Přidat nového administrátora</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <form @submit="addAdmin">
            <div class="modal-body">
              <div class="mb-3">
                <label for="prize-name" class="col-form-label">Email</label>
                <input type="text" class="form-control" ref="email" v-model="currentAdmin"/>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zavřít</button>
              <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">Přidat</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <h1 class="text-center"> Vítejte v administrační stránce </h1>
    <h2 class="text-center"> Pro přihlášení zadejte svůj email, kterému byl zajištěn přístup </h2>
    <br>
    <form>
      <div class="row w-100 justify-content-center">
        <div class="col-5">
          <label for="exampleInputEmail1" class="form-label">Emailová adresa</label>
          <input type="email" class="form-control" id="exampleInputEmail1" v-model="writtenEmail" aria-describedby="emailHelp">
        </div>
      </div>
      <br>
      <div class="d-flex justify-content-center">
        <button type="submit" class="btn btn-primary" @click="login()">Přihlásit se</button>
      </div>
    </form>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      logged: false,
      writtenEmail: "",
      currentAdmin: ""
    }
  },
  methods: {
    async login() {
      const res = await fetch(this.$apiRoute + "/admin/login", {
        method: "POST",
        headers: this.$headers,
        body: this.writtenEmail
      })

      const response = await res.text()
      if( response === "Correct" ) {
        this.logged = true
      } else {
        alert("Zadali jste nesprávný email. Zkuste to znovu nebo kontaktujte správce.")
      }

      this.writtenEmail = ""
    },
    clearCurrentAdmin() {
      this.currentAdmin = ""
    },
    async addAdmin(e) {
      e.preventDefault()
      await fetch(this.$apiRoute + "/admin", {
        method: "POST",
        headers: this.$headers,
        body: this.currentAdmin
      })

      this.clearCurrentAdmin()
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
