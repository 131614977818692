<script>
export default {
  name: "HomePage",
  data() {
    return {
      errors: []
    }
  },
  methods: {
    async fetchErrors() {
      const res = await fetch(this.$apiRoute + "/errors", {
        headers: this.$headers
      })
      this.errors = await res.json()
    },
    async solveError(errorID) {
      await fetch(this.$apiRoute + "/errors/"+errorID, {
        method: 'DELETE',
        headers: this.$headers
      })

      await this.fetchErrors()
    }
  },
  mounted() {
    this.fetchErrors()
  }
}
</script>

<template>
  <h1>Vítejte na domovské stránce</h1>

  <h2> Tato stránka slouží pro administraci uživatelů a cen. </h2>

  <br>
  <h2><b>Níže můžete vidět seznam chyb, které se naskytli během minulé aktualizace akcí z kalendáře</b></h2>

  <table class="table table-responsive table-striped-columns align-middle table-hover">
    <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Error hláška</th>
      <th scope="col">Akce, které se to týká</th>
    </tr>
    </thead>
    <tbody class="table-group-divider">
    <tr v-for="error in errors" :key="error.id">
      <td>{{error.id}}</td>
      <td>{{error.errorMesage}}</td>
      <td>{{error.eventSerialization}}</td>
      <td style="width: 20%;">
        <div class="d-grid gap-3 mx-auto d-md-flex justify-content-center">
          <a role="button" class="btn btn-success" @click="solveError(error.id)">Problém vyřešen</a>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<style scoped>

</style>